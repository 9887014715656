const actions = {
  GET_ASSET_PARTS: "GET_ASSET_PARTS",
  ADD_ASSET_PART: "ADD_ASSET_PART",
  UPDATE_ASSET_PART: "UPDATE_ASSET_PART",
  DELETE_ASSET_PART: "DELETE_ASSET_PART",
  GET_ASSET_PARTS_REDUCER: "GET_ASSET_PARTS_REDUCER",

  getAssetParts: (assetId) => ({ 
    type: actions.GET_ASSET_PARTS, 
    payload: assetId
  }),

  add: (sendData) => {
    return (dispatch, getState) => {
      dispatch({ 
        type: actions.ADD_ASSET_PART, 
        payload: { sendData: sendData } 
      });
    };
  },

  deleteData: (selected, parentId) => {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_ASSET_PART,
        payload: { id: selected, parentId: parentId }
      });
    };
  },

  updateData: (sendData, id) => {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_ASSET_PART,
        payload: { sendData: sendData, id: id },
      });
    };
  },
};
export default actions;
