import actions from './actions';
const initState = {
  tripleusers: [],
  tripleuser: {},
  isDelete: false,
};

export default function TripleUser(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_TRIPLESEAT_USER_REDUCER: {
      return {
        ...state,
        tripleusers: action,
        isDelete: false,
      };
    }
    case actions.GET_BY_ID_USER_REDUCER: {
      return {
        ...state,
        tripleuser: action.data.user,
        isDelete: false,
      };
    }
    default:
      return state;
  }
}
