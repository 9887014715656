import actions from './actions';
const initState = {
  tripleaccounts: [],
  tripleaccount: {},
  success: false,
  isDelete: false,
};

export default function TripleReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.GET_TRIPLESEAT_ACCOUNT_REDUCER: {
      return {
        ...state,
        tripleaccounts: action,
        success: true,
      };
    }
    case actions.ADD_TRIPLESEAT_ACCOUNT: {
      return {
        ...state,
        tripleaccounts: [],
        success: true,
      };
    }
    case actions.UPDATE_ACCOUNT_SUCCESS: {
      return {
        ...state,
        isDelete: false,
      };
    }
    case actions.ADD_ACCOUNT_FAILE: {
      return {
        ...state,
        tripleaccounts: [],
        success: false,
      };
    }
    case actions.GET_BY_ID_ACCOUNT_REDUCER: {
      return {
        ...state,
        tripleaccount: action.data.account,
        isDelete: false,
      };
    }
    case actions.DELETE_SUCCESS: {
      return {
        ...state,
        isDelete: true,
      };
    }
    case actions.DELETE_FAILED: {
      return {
        ...state,
        isDelete: false,
      };
    }
    default:
      return state;
  }
}
