const actions = {
  GET_CONTACT: 'GET_CONTACT',
  GET_CONTACT_REDUCER: 'GET_CONTACT_REDUCER',
  ADD_CONTACT: 'ADD_CONTACT',
  ADD_CONTACT_REDUCER: 'ADD_CONTACT_REDUCER',
  UPDATE_CONTACT: 'UPDATE_CONTACT',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  DELETE_CONTACT: 'DELETE_CONTACT',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  GET_BY_ID_CONTACT: 'GET_BY_ID_CONTACT',
  GET_BY_ID_CONTACT_REDUCER: 'GET_BY_ID_CONTACT_REDUCER',

  getContact: () => ({ type: actions.GET_CONTACT }),
  addContact: (sendData) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.ADD_CONTACT,
        payload: { sendData: sendData },
      });
    };
  },
  getByIdContact: (id) => ({
    type: actions.GET_BY_ID_CONTACT,
    payload: { id: id },
  }),
  updateData: (sendData, id) => {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_CONTACT,
        payload: { sendData: sendData, id: id },
      });
    };
  },
  deleteData: (selected) => {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_CONTACT,
        payload: { id: selected },
      });
    };
  },
};

export default actions;
