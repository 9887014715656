const actions = {
  GET_PORTFACILITY_CONTACT: 'GET_PORTFACILITY_CONTACT',
  ADD_PORTFACILITY_CONTACT: 'ADD_PORTFACILITY_CONTACT',
  DELETE_PORTFACILITY_CONTACT: 'DELETE_PORTFACILITY_CONTACT',
  GET_PORTFACILITY_CONTACT_REDUCER: 'GET_PORTFACILITY_CONTACT_REDUCER',
  UPDATE_PORTFACILITY_CONTACT: 'UPDATE_PORTFACILITY_CONTACT',
  CHANGED_CONTACT_REDUCER: 'CHANGED_CONTACT_REDUCER',
  addContact: (sendData) => {
    return (dispatch, getState) => {
      dispatch({ type: actions.ADD_PORTFACILITY_CONTACT, payload: { sendData: sendData } });
    };
  },
  getContact: (PFID) => {
    return (dispatch) => {
      dispatch({ type: actions.GET_PORTFACILITY_CONTACT, payload: { id: PFID } });
    };
  },
  deleteData: (selected) => {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_PORTFACILITY_CONTACT,
        payload: { id: selected },
      });
    };
  },
  updateData: (sendData, id) => {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_PORTFACILITY_CONTACT,
        payload: { sendData: sendData, id: id },
      });
    };
  },
};
export default actions;
