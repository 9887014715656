import actions from './actions';
const initstate = {
  locations: [],
  isDelete: false,
};

export default function TripleLocation(state = initstate, { type, ...action }) {
  switch (type) {
    case actions.GET_TRIPLESEAT_LOCATION_REDUCER: {
      return {
        ...state,
        locations: action,
        isDelete: false,
      };
    }
    default:
      return state;
  }
}
