import { all, takeEvery, put,call } from 'redux-saga/effects';
import axios from 'axios'
import siteConfig from '@iso/config/site.config';
import notification from '@iso/components/Notification';
import actions from './actions';

const onCallReqeust = async (URI) =>
  await axios
  .get(URI)  
    .then(res => res)
    .catch(error => error);

const onPostCallReqeust = async (sendData,URI) =>
  await axios
  .post(URI,sendData)  
    .then(res => res)
    .catch(error => error);

const onPutCallReqeust = async (sendData,URI) =>
  await axios
  .put(URI,sendData)  
    .then(res => res)
    .catch(error => error);

const onDeleteCallReqeust = async (URI) =>
  await axios
  .delete(URI)  
    .then(res => res)
    .catch(error => error);

export function* getAssetParts({payload}) {
  axios.defaults.headers.get['Authorization'] = localStorage.getItem('id_token');
  try {
    const callResult = yield call(
      onCallReqeust,
      `${siteConfig.apiUrl}/assetpart/${payload}`
    );
     
    if(callResult.response !== undefined){
      notification('error',callResult.response.data.msg);
    }
    else{
      var temp=[];    
      callResult.data.data.map((value, index) => {          
           value.key=value._id;          
           temp.push(value);
       });     
      
       yield put({
         type: actions.GET_ASSET_PARTS_REDUCER,
         data:temp,
       });
    }  
   
  }
  catch (error) {
    notification('error',"Internal server error!")
  }  
}

export function* addAssetPart({payload}) { 
  axios.defaults.headers.post['Authorization'] = localStorage.getItem('id_token');
  try {
    const callResult = yield call(
      onPostCallReqeust,
      payload.sendData,
      `${siteConfig.apiUrl}/assetpart`
    );

    if(callResult.response!==undefined){
      notification('error',callResult.response.data.msg)     
    }
    else{
        notification('success',callResult.data.msg)
        yield put({
          type: actions.GET_ASSET_PARTS, 
          payload: payload.sendData.intParentId
        });
    }
  }
  catch (error) {   
    notification('error',"Server Internal error!")
  }  
}

export function* updateAssetPart({payload}) { 
  axios.defaults.headers.put['Authorization'] =  localStorage.getItem('id_token');
  try {
    const callResult = yield call(
      onPutCallReqeust,
      payload.sendData,
      `${siteConfig.apiUrl}/assetpart/${payload.id}`
    );
  
    if(callResult.response!=undefined){
      notification('error',callResult.response.data.msg)     
    }
    else{
     notification('success',callResult.data.msg)
      yield put({
        type: actions.GET_ASSET_PARTS,   
        payload: payload.sendData.intAssetID  
      });
    }  
  }
  catch (error) {  
    notification('success',"Server Internal error!")   
  }  
}

export function* deleteAssetPart({payload}) { 
  axios.defaults.headers.delete['Authorization'] =localStorage.getItem('id_token');
  try {
    const callResult = yield call(
      onDeleteCallReqeust,     
      `${siteConfig.apiUrl}/assetpart/${payload.id}`          
    );
    if(callResult.response!=undefined){
      notification('error',callResult.response.data.msg)
    }
    else{
      notification('success',callResult.data.msg)
      yield put({
        type: actions.GET_ASSET_PARTS,
        payload: payload.parentId
      });
    }     

  }
  catch (error) {     
    notification('error',"Server Internal error!")      
  }  
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.ADD_ASSET_PART, addAssetPart),
    yield takeEvery(actions.GET_ASSET_PARTS, getAssetParts),
    yield takeEvery(actions.UPDATE_ASSET_PART, updateAssetPart),
    yield takeEvery(actions.DELETE_ASSET_PART, deleteAssetPart),
  ]);
}
