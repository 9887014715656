const actions = {
  GET_TRIPLESEAT_USER: 'GET_TRIPLESEAT_USER',
  GET_TRIPLESEAT_USER_REDUCER: 'GET_TRIPLESEAT_USER_REDUCER',
  GET_BY_ID_USER: 'GET_BY_ID_USER',
  GET_BY_ID_USER_REDUCER: 'GET_BY_ID_USER_REDUCER',

  getUser: () => ({ type: actions.GET_TRIPLESEAT_USER }),

  getByIdEvent: (id) => {
    return (dispatch) => {
      dispatch({
        type: actions.GET_BY_ID_USER,
        payload: { id: id },
      });
    };
  },
};

export default actions;
