const actions = {
  ADD_PASSENGERTAG: 'ADD_PASSENGERTAG',
  GET_PASSENGERTAG_DATAS: 'GET_PASSENGERTAG_DATAS',
  GET_PASSENGERTAG_DATAS_REDUCER: 'GET_PASSENGERTAG_DATAS_REDUCER',
  DELETE_PASSENGERTAG: 'DELETE_PASSENGERTAG',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  DELETE_FAILED: 'DELETE_FAILED',

  addPassengerTag: (sendData) => {
    return (dispatch, getState) => {
      dispatch({ type: actions.ADD_PASSENGERTAG, payload: { sendData: sendData } });
    };
  },
  getPassengerTagsData: () => ({ type: actions.GET_PASSENGERTAG_DATAS }),
  // getById: (id) => {
  //   return (dispatch) => {
  //     dispatch({ type: actions.GET_PASSENGER_BY_ID, payload: { id: id } });
  //   };
  // },
  deleteData: (selected) => {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_PASSENGERTAG,
        payload: { id: selected },
      });
    };
  },
};
export default actions;
