const actions = {
  GET_BOOKING: 'GET_BOOKING',
  GET_BOOKING_REDUCER: 'GET_BOOKING_REDUCER',
  ADD_BOOKING: 'ADD_BOOKING',
  ADD_BOOKING_REDUCER: 'ADD_BOOKING_REDUCER',
  UPDATE_BOOKING: 'UPDATE_BOOKING',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  DELETE_BOOKING: 'DELETE_BOOKING',
  DELETE_SUCCESS: 'DELETE_SUCCESS',

  getBooking: () => ({ type: actions.GET_BOOKING }),
  addBooking: (sendData) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.ADD_BOOKING,
        payload: { sendData: sendData },
      });
    };
  },

  updateData: (sendData, id) => {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_BOOKING,
        payload: { sendData: sendData, id: id },
      });
    };
  },
  deleteData: (selected) => {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_BOOKING,
        payload: { id: selected },
      });
    };
  },
};

export default actions;
