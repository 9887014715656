import actions from './actions';
const initstate = {
  sites: [],
  // site: {},
  isDelete: false,
};

export default function TripleSite(state = initstate, { type, ...action }) {
  switch (type) {
    case actions.GET_TRIPLESEAT_SITE_REDUCER: {
      return {
        ...state,
        sites: action,
        isDelete: false,
      };
    }
    default:
      return state;
  }
}
