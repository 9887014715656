import { all, takeEvery, put, call } from 'redux-saga/effects';
import axios from 'axios';
import siteConfig from '@iso/config/site.config';
import notification from '@iso/components/Notification';
import actions from './actions';

const onCallReqeust = async (URI) =>
  await axios
    .get(URI)
    .then((res) => res)
    .catch((error) => error);
const onPostCallReqeust = async (sendData, URI) =>
  await axios
    .post(URI, sendData)
    .then((res) => res)
    .catch((error) => error);
const onPutCallReqeust = async (sendData, URI) =>
  await axios
    .put(URI, sendData)
    .then((res) => res)
    .catch((error) => error);
const onDeleteCallReqeust = async (URI) =>
  await axios
    .delete(URI)
    .then((res) => res)
    .catch((error) => error);

export function* getAccount() {
  try {
    const callResult = yield call(
      onCallReqeust,
      `${siteConfig.apiUrl}/tripleseataccount`
    );
    yield put({
      type: actions.GET_TRIPLESEAT_ACCOUNT_REDUCER,
      tripleaccounts: callResult.data.results, //createDemoData(),
    });
  } catch (error) {
    notification('error', 'Internal server error!');
  }
}

export function* addAccount({ payload }) {
  try {
    const callResult = yield call(
      onPostCallReqeust,
      payload.sendData,
      `${siteConfig.apiUrl}/tripleseataccount`
    );
    if (callResult.response != undefined) {
      notification('error', callResult.response.data.errors.name[0]);
    } else {
      notification('success', 'Creat Successfully');
      yield put({
        type: actions.GET_TRIPLESEAT_ACCOUNT,
        tripleaccounts: callResult.data.data,
      });
    }
  } catch (error) {
    console.log(error);
    notification('error', 'Internal server error!');
  }
}

export function* getById({ payload }) {
  try {
    const callResult = yield call(
      onCallReqeust,
      `${siteConfig.apiUrl}/tripleseataccount/${payload.id}`
    );
    yield put({
      type: actions.GET_BY_ID_ACCOUNT_REDUCER,
      data: callResult.data,
    });
  } catch (error) {
    notification('error', 'Internal server error!');
  }
}

export function* updateData({ payload }) {
  try {
    const callResult = yield call(
      onPutCallReqeust,
      payload.sendData,
      `${siteConfig.apiUrl}/tripleseataccount/${payload.id}`
    );
    notification('success', 'Update Successfully');
  } catch (error) {
    notification('error', 'Server Internal error!');
  }
}

export function* deleteData({ payload }) {
  try {
    const callResult = yield call(
      onDeleteCallReqeust,
      payload.sendData,
      `${siteConfig.apiUrl}/tripleseataccount/${payload.id}`
    );
    yield put({
      type: actions.DELETE_SUCCESS,
    });
    notification('success', 'Delete Successfully');
  } catch (error) {
    notification('error', 'Server Internal error!');
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.GET_TRIPLESEAT_ACCOUNT, getAccount),
    yield takeEvery(actions.ADD_TRIPLESEAT_ACCOUNT, addAccount),
    yield takeEvery(actions.GET_BY_ID_ACCOUNT, getById),
    yield takeEvery(actions.UPDATE_ACCOUNT_TRIPLE, updateData),
    yield takeEvery(actions.DELETE_ACCOUNT, deleteData),
  ]);
}
