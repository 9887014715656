import actions from './actions';
const initState = {  
  userroles:[],
  userrole:{},
  isDelete:false,
  isSaved:false,
  isLoading: false,
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.ADD_USERROLE_SUCCESS:
      return {
        ...state,             
        userroles: [],    
        isLoading: false,
        isDelete:false,
        isSaved:true  
      };
    case actions.GET_USERROLE_REDUCER: {
      return {
        ...state,
        userroles: action.userroles,      
        isLoading: true,
        isDelete:false,
        isSaved:false
      };
    }
    case actions.GET_ROLE_BY_ID_REDUCER: {
      return {
        ...state,
        userrole: action.data,  
        isLoading: true,
        isDelete:false,
        isSaved:false 
      };
    }
    case actions.DELETE_USER_ROLE_SUCCESS:
      return {
        ...state, 
        isLoading: false,      
        isDelete:true
      };  
    default:
      return state;
  }
}
