const actions = {
  GET_PORT_FACILITIES: "GET_PORT_FACILITIES",
  GET_PORT_FACILITIES_REDUCER: "GET_PORT_FACILITIES_REDUCER",
  ADD_FACILITY: "ADD_FACILITY",
  UPDATE_PORT_FACILITY: "UPDATE_PORT_FACILITY",
  DELETE_PORT_FACILITY: "DELETE_PORT_FACILITY",
  CHANGED_REDUCER: "CHANGED_REDUCER",
  GET_PORT_FACILITY_BY_ID: 'GET_PORT_FACILITY_BY_ID',
  GET_PORT_FACILITY_BY_ID_REDUCER: 'GET_PORT_FACILITY_BY_ID_REDUCER',

  initData: (vesselId) => ({ type: actions.GET_PORT_FACILITIES, payload:{ vesselId: vesselId } }),
  add: (sendData) => {
    return (dispatch, getState) => {
      dispatch({ type: actions.ADD_FACILITY, payload: { sendData: sendData } });
    };
  },
  getById: (id) => {
    return (dispatch) => {
      dispatch({ type: actions.GET_PORT_FACILITY_BY_ID, payload: { id: id } });
    };
  },
  deleteData: (selected) => {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_PORT_FACILITY,
        payload: { id: selected }
      });
    };
  },
  updateData: (sendData, id) => {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_PORT_FACILITY,
        payload: { sendData: sendData, id: id },
      });
    };
  },

};
export default actions;
