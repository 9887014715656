import { all, takeEvery, put, call } from 'redux-saga/effects';
import axios from 'axios';
import siteConfig from '@iso/config/site.config';
import notification from '@iso/components/Notification';
import actions from './actions';

const onCallReqeust = async (URI) =>
  await axios
    .get(URI)
    .then((res) => res)
    .catch((error) => error);
const onPostCallReqeust = async (sendData, URI) =>
  await axios
    .post(URI, sendData)
    .then((res) => res)
    .catch((error) => error);
const onPutCallReqeust = async (sendData, URI) =>
  await axios
    .put(URI, sendData)
    .then((res) => res)
    .catch((error) => error);
const onDeleteCallReqeust = async (URI) =>
  await axios
    .delete(URI)
    .then((res) => res)
    .catch((error) => error);

export function* getEvent() {
  try {
    const callResult = yield call(
      onCallReqeust,
      `${siteConfig.apiUrl}/tripleseatevent`
    );
    yield put({
      type: actions.GET_TRIPLESEAT_EVENT_REDUCER,
      events: callResult.data.results, //createDemoData(),
    });
  } catch (error) {
    notification('error', 'Internal server error!');
  }
}

export function* addEvent({ payload }) {
  try {
    const callResult = yield call(
      onPostCallReqeust,
      payload.sendData,
      `${siteConfig.apiUrl}/tripleseatevent`
    );
    notification('success', 'Create Successfully!');
    yield put({
      type: actions.GET_TRIPLESEAT_EVENT,
      // tripleaccounts: callResult.data.data,
      payload: { id: payload.sendData.account_id },
    });
  } catch (error) {
    console.log(error);
    notification('error', 'Internal server error!');
  }
}

export function* getByIdEvent({ payload }) {
  try {
    const callResult = yield call(
      onCallReqeust,
      `${siteConfig.apiUrl}/tripleseatevent/${payload.id}`
    );
    console.log(callResult, 'callResult');
    yield put({
      type: actions.GET_BY_ID_EVENT_REDUCER,
      event: callResult.data,
    });
  } catch (error) {
    console.log(error);
    notification('error', 'Internal server error!');
  }
}

export function* updateData({ payload }) {
  try {
    const callResult = yield call(
      onPutCallReqeust,
      payload.sendData,
      `${siteConfig.apiUrl}/tripleseatevent/${payload.id}`
    );
    notification('success', 'Update Successfully');
    yield put({
      type: actions.UPDATE_SUCCESS,
      payload: { id: payload.sendData.account_id },
    });
  } catch (error) {
    notification('error', 'Server Internal error!');
  }
}

export function* deleteData({ payload }) {
  try {
    const call = yield call(
      onDeleteCallReqeust,
      payload.sendData,
      `${siteConfig.apiUrl}/tripleseatevent/${payload.id}`
    );
    notification('success', 'Delete Successfully');
    yield put({
      type: actions.DELETE_SUCCESS,
    });
  } catch (error) {
    notification('error', 'Server Internal error!');
  }
}

export default function* rootSaga() {
  yield all([
    yield takeEvery(actions.GET_TRIPLESEAT_EVENT, getEvent),
    yield takeEvery(actions.ADD_TRIPLESEAT_EVENT, addEvent),
    yield takeEvery(actions.GET_BY_ID_EVENT, getByIdEvent),
    yield takeEvery(actions.UPDATE_EVENT, updateData),
    yield takeEvery(actions.DELETE_EVENT, deleteData),
  ]);
}
