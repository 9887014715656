const actions = {
  GET_TRIPLESEAT_EVENT: 'GET_TRIPLESEAT_EVENT',
  GET_TRIPLESEAT_EVENT_REDUCER: 'GET_TRIPLESEAT_EVENT_REDUCER',
  GET_BY_ID_EVENT: 'GET_BY_ID_EVENT',
  GET_BY_ID_EVENT_REDUCER: 'GET_BY_ID_EVENT_REDUCER',
  ADD_TRIPLESEAT_EVENT: 'ADD_TRIPLESEAT_EVENT',
  ADD_EVENT_SUCCESS: 'ADD_EVENT_SUCCESS',
  ADD_EVENT_FAILE: 'ADD_EVENT_FAILE',
  UPDATE_EVENT: 'UPDATE_EVENT',
  UPDATE_SUCCESS: 'UPDATE_SUCCESS',
  DELETE_EVENT: 'DELETE_EVENT',
  DELETE_SUCCESS: 'DELETE_SUCCESS',
  DELETE_FAILED: 'DELETE_FAILED',

  getEvent: () => ({ type: actions.GET_TRIPLESEAT_EVENT }),

  addEvent: (sendData) => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.ADD_TRIPLESEAT_EVENT,
        payload: { sendData: sendData },
      });
    };
  },

  getByIdEvent: (id) => {
    return (dispatch) => {
      dispatch({
        type: actions.GET_BY_ID_EVENT,
        payload: { id: id },
      });
    };
  },

  updateData: (sendData, id) => {
    return (dispatch) => {
      dispatch({
        type: actions.UPDATE_EVENT,
        payload: { sendData: sendData, id: id },
      });
    };
  },
  deleteData: (selected) => {
    return (dispatch) => {
      dispatch({
        type: actions.DELETE_EVENT,
        payload: { id: selected },
      });
    };
  },
};
export default actions;
