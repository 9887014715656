// import clone from 'clone';
import actions from './actions';
const initState = {  
  audits:[],
  audit:{},
  isLoading: false,
  isDelete:false,
  isSaved:false
};

export default function cardReducer(state = initState, { type, ...action }) {
  switch (type) {
    case actions.ADD_ADUIT_SUCCESS:
      return {
        ...state,             
        audits: [],   
        isLoading: false,  
        isSaved:true   
      };
    case actions.ADD_FAILED:
      return {
        ...state,        
        audits: [],    
        isLoading: false,
        isSaved:false         
      };
    case actions.GET_AUDIT_REDUCER: {
      return {
        ...state,
        audits: action.audits, 
        isLoading: true,     
        isDelete:false,
        isSaved:false
      };
    }
    case actions.GET_AUDIT_BY_ID_REDUCER: {
      return {
        ...state,
        audit: action.audit,    
        isLoading: true,   
        isSaved:false 
      };
    }
    case actions.UPDATE_SUCCESS:
      return {
        ...state,      
        audits: [],
        isLoading: false,
        isSaved:false
      };
    case actions.DELETE_FAILED:
      return {
        ...state,    
        isLoading: false,
        isDelete:false,
        isSaved:false
      };
    case actions.DELETE_SUCCESS:
      return {
        ...state,    
        isLoading: false,     
        isDelete:true,
        isSaved:false
      };  
    default:
      return state;
  }
}
