
//const API_URL = 'http://localhost:8080/api';
const API_URL = 'https://sms.seafairmiami.com/api';
const WEATHER_API_URL='https://api.stormglass.io/v2/weather/point?lat=25.772320&lng=-80.185490&params=airTemperature,pressure,cloudCover,currentDirection,currentSpeed,gust,humidity,precipitation,seaLevel,swellDirection,swellHeight,swellPeriod,visibility,waterTemperature,waveDirection,waveHeight,wavePeriod,windDirection,windSpeed';
const WEATHER_API_KEY='cc07cc62-6fc3-11eb-958b-0242ac130002-cc07cce4-6fc3-11eb-958b-0242ac130002';
const GOOGLE_ANALYTICS='UA-xxxxxxxxx-1'


console.log(API_URL)
export default {
  siteName: 'SEAFAIR',
  siteIcon: 'ion-flash',
  footerText: `ISOMORPHIC @ ${new Date().getFullYear()} Created by RedQ, Inc`,
  enableAnimatedRoute: false,
  apiUrl: API_URL,
  weatherApiUrl: WEATHER_API_URL,
  weatherApiKey: WEATHER_API_KEY,
  google: {
    analyticsKey: GOOGLE_ANALYTICS,
  },
  dashboard: '/dashboard',
};
